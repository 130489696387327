import React from 'react'
import { match } from 'ts-pattern'
import classNamesBind from 'classnames/bind'
import styles from './FixedColorIcon.module.scss'
const cn = classNamesBind.bind(styles)

export enum FixedColorIcons {
  Browser = 'Browser',
  BrowserGray = 'BrowserGray',
  CalendarDetailed = 'CalendarDetailed',
  CalendarPlaybook = 'CalendarPlaybook',
  CalendarPlus = 'CalendarPlus',
  CheckmarkGreenCircle = 'CheckmarkGreenCircle',
  CheckmarkInBox = 'CheckmarkInBox',
  CheckmarkToggle = 'CheckmarkToggle',
  Checklist = 'Checklist',
  ClassicPhone = 'ClassicPhone',
  DetailedMonitor = 'DetailedMonitor',
  DossierBriefcase = 'DossierBriefcase',
  ExclamationYellow = 'ExclamationYellow',
  EyeStrikethrough = 'EyeStrikethrough',
  FeedbackLoop = 'FeedbackLoop',
  LinkedInGray = 'LinkedInGray',
  HeartInHand = 'HeartInHand',
  InfoBlue = 'InfoBlue',
  MagnifyingGlassColor = 'MagnifyingGlassColor',
  PriceTag = 'PriceTag',
  RedX = 'RedX',
  RedXCircle = 'RedXCircle',
  RefreshDetailed = 'RefreshDetailed',
  ShoppingCartDetailed = 'ShoppingCartDetailed',
  SignedPaper = 'SignedPaper',
  TakeoutCoffee = 'TakeoutCoffee',
  ThumbsDownCuff = 'ThumbsDownCuff',
  XToggle = 'XToggle',
  MagnifyingGlass = 'MagnifyingGlass',
}

export type Props = {
  icon: FixedColorIcons | undefined | null
  className?: string
  style?: React.CSSProperties
}

export default function FixColorIcon({
  icon,
  className = '',
  style = {},
}: Props) {
  const FixedColorIconComponent = match(icon)
    .with(FixedColorIcons.Checklist, () => (
      <svg viewBox="0 0 105.715 135.277">
        <g transform="translate(-131 -1915.212)">
          <g transform="translate(132 1916.212)">
            <path
              d="M3339.328,3984.1H3238.937V3854.145h78.043l22.348,22.2Z"
              transform="translate(-3238.937 -3854.145)"
            />
            <line x2="42.48" transform="translate(42.113 34.747)" />
            <line x2="42.48" transform="translate(42.113 60.088)" />
            <line x2="42.48" transform="translate(42.113 85.429)" />
            <line x2="42.48" transform="translate(42.113 110.77)" />
            <path
              d="M3314.949,3876.344h-22.2v-22.2Z"
              transform="translate(-3214.558 -3854.145)"
            />
            <path
              d="M3264.54,3876.085v6.111h-13.708V3868.49h6.993"
              transform="translate(-3233.547 -3847.646)"
            />
            <path
              d="M3253.541,3874.9l3.059,2.978,8.915-12.022"
              transform="translate(-3232.32 -3848.839)"
            />
            <path
              d="M3264.54,3893.032v6.111h-13.708v-13.707h6.993"
              transform="translate(-3233.547 -3839.969)"
            />
            <path
              d="M3253.541,3891.846l3.059,2.978,8.915-12.022"
              transform="translate(-3232.32 -3841.161)"
            />
            <path
              d="M3264.54,3911.092v6.111h-13.708V3903.5h6.993"
              transform="translate(-3233.547 -3831.787)"
            />
            <path
              d="M3253.541,3909.907l3.059,2.978,8.915-12.022"
              transform="translate(-3232.32 -3832.98)"
            />
            <path
              d="M3264.54,3928.843v6.111h-13.708v-13.707h6.993"
              transform="translate(-3233.547 -3823.746)"
            />
            <path
              d="M3253.541,3927.657l3.059,2.978,8.915-12.022"
              transform="translate(-3232.32 -3824.938)"
            />
          </g>
          <path
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
            d="M3339.328,3984.1H3238.937V3854.145h78.043l22.348,22.2Z"
            transform="translate(-3102.613 -1933.609)"
          />
        </g>
      </svg>
    ))
    .with(FixedColorIcons.HeartInHand, () => (
      <svg viewBox="0 0 133.432 116.722">
        <g transform="translate(-1232 -7192)">
          <g transform="translate(1233 7193)">
            <rect
              width="16.306"
              height="49.325"
              transform="translate(16.306 113.037) rotate(180)"
              style={{ fill: 'var(--color-glass)' }}
            />
            <path
              d="M2288.162,2196.3s18.573-14.826,44.157-.444l20.462-.3s5.157-.465,5.157,4.51c0,5.2-3.994,4.987-3.994,4.987l-32.687,1.152"
              transform="translate(-2271.856 -2121.771)"
            />
            <path
              d="M2288.162,2228.627s18.769-3.641,35.112.462,23.9,1.081,23.9,1.081l53.928-34.56s4.429-3.345.615-8.155c-3.837-4.84-8.391-1.709-8.391-1.709l-34.855,17.464"
              transform="translate(-2271.856 -2123.911)"
            />
            <path
              className="CE Parented fill-disc-light"
              d="M2350.948,2142.6c-6.444,0-12.073,5.042-15.122,10.223-3.047-5.181-8.676-10.223-15.122-10.223a17.537,17.537,0,0,0-17.535,17.536c0,21.223,32.657,43.3,32.657,43.3s32.659-21.826,32.659-43.3A17.537,17.537,0,0,0,2350.948,2142.6Z"
              transform="translate(-2265.203 -2142.599)"
              style={{
                fill: 'var(--color-glass)',
              }}
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.DossierBriefcase, () => (
      <svg viewBox="0 0 136.726 96.884">
        <g transform="translate(1.297 1)">
          <g transform="translate(0 0)">
            <rect
              width="130.631"
              height="80.792"
              transform="translate(0 10.593)"
            />
            <line
              y1="21.153"
              x2="53.216"
              transform="translate(77.415 29.809)"
            />
            <line x2="53.205" y2="21.009" transform="translate(0 29.98)" />
            <path
              d="M3633.479,1179.314h0a10.593,10.593,0,0,1,10.592-10.593h23.592a10.593,10.593,0,0,1,10.593,10.593h0"
              transform="translate(-3591.586 -1168.721)"
            />
            <rect
              width="24.221"
              height="11.69"
              transform="translate(53.205 47.086)"
            />
          </g>
          <g
            transform="translate(3.499 3.498)"
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
          >
            <rect
              width="130.631"
              height="80.792"
              transform="translate(0 10.593)"
            />
            <line
              y1="21.153"
              x2="53.216"
              transform="translate(77.415 29.809)"
            />
            <line x2="53.205" y2="21.009" transform="translate(0 29.98)" />
            <path
              d="M3633.479,1179.314h0a10.593,10.593,0,0,1,10.592-10.593h23.592a10.593,10.593,0,0,1,10.593,10.593h0"
              transform="translate(-3591.586 -1168.721)"
            />
            <rect
              width="24.221"
              height="11.69"
              transform="translate(53.205 47.086)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.FeedbackLoop, () => (
      <svg viewBox="0 0 133.353 135.621">
        <g transform="translate(-124.035 -1643.282)">
          <g transform="translate(125 1644.25)">
            <path
              d="M2845.309,1086.645s1.625-11.768-3.049-16.578a26.7,26.7,0,0,0-10.741-4.718l-.468.467a19.018,19.018,0,0,1-26.86-.467,27.169,27.169,0,0,0-10.742,4.718c-2.787,3.677-2.2,7.248-2.787,16.309C2790.662,1086.376,2811.76,1099.725,2845.309,1086.645Z"
              transform="translate(-2790.662 -1030.453)"
            />
            <path
              d="M2827.914,1054.067c0,7.552-4.831,18.681-14.043,18.681s-14.027-11.129-14.027-18.681a14.043,14.043,0,0,1,28.037,0"
              transform="translate(-2786.767 -1040.847)"
            />
            <path
              d="M2831.991,1049.978h42.282V1091.9"
              transform="translate(-2773.13 -1036.973)"
            />
            <path
              d="M2883.239,1069.055l-16.613,16.613-16.612-16.613"
              transform="translate(-2765.484 -1028.88)"
            />
            <path
              d="M2847.153,1141.884h-38.525v-41.925"
              transform="translate(-2783.041 -1015.771)"
            />
            <path
              d="M2796.964,1115.266l16.613-16.613,16.613,16.613"
              transform="translate(-2787.989 -1016.324)"
            />
            <g
              transform="translate(73.682 68.912)"
              style={{
                fill: 'var(--color-glass)',
                stroke: 'none',
              }}
            >
              <path
                d="M2897.046,1135.032s1.624-11.77-3.051-16.579a26.72,26.72,0,0,0-10.74-4.718l-.467.467a19.02,19.02,0,0,1-26.862-.467,27.18,27.18,0,0,0-10.741,4.718c-2.787,3.677-2.2,7.249-2.787,16.31C2842.4,1134.763,2863.5,1148.112,2897.046,1135.032Z"
                transform="translate(-2842.397 -1078.839)"
              />
              <path
                d="M2879.648,1102.453c0,7.553-4.831,18.68-14.043,18.68s-14.025-11.127-14.025-18.68a14.042,14.042,0,0,1,28.035,0"
                transform="translate(-2838.502 -1089.233)"
              />
            </g>
          </g>
          <g
            transform="translate(127.811 1647.061)"
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
          >
            <path
              d="M2845.309,1086.645s1.625-11.768-3.049-16.578a26.7,26.7,0,0,0-10.741-4.718l-.468.467a19.018,19.018,0,0,1-26.86-.467,27.169,27.169,0,0,0-10.742,4.718c-2.787,3.677-2.2,7.248-2.787,16.309C2790.662,1086.376,2811.76,1099.725,2845.309,1086.645Z"
              transform="translate(-2790.662 -1030.453)"
            />
            <path
              d="M2827.914,1054.067c0,7.552-4.831,18.681-14.043,18.681s-14.027-11.129-14.027-18.681a14.043,14.043,0,0,1,28.037,0"
              transform="translate(-2786.767 -1040.847)"
            />
          </g>
          <g transform="translate(201.493 1715.973)">
            <path
              d="M2897.046,1135.032s1.624-11.77-3.051-16.579a26.72,26.72,0,0,0-10.74-4.718l-.467.467a19.02,19.02,0,0,1-26.862-.467,27.18,27.18,0,0,0-10.741,4.718c-2.787,3.677-2.2,7.249-2.787,16.31C2842.4,1134.763,2863.5,1148.112,2897.046,1135.032Z"
              transform="translate(-2842.397 -1078.839)"
            />
            <path
              d="M2879.648,1102.453c0,7.553-4.831,18.68-14.043,18.68s-14.025-11.127-14.025-18.68a14.042,14.042,0,0,1,28.035,0"
              transform="translate(-2838.502 -1089.233)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.EyeStrikethrough, () => (
      <svg viewBox="0 0 139.524 134.933">
        <g transform="translate(-111 -189.586)">
          <g transform="translate(112 191)">
            <g transform="translate(0 33.596)">
              <path
                d="M4205.625,857.7s-30.786-36.372-68.763-36.372S4068.1,857.7,4068.1,857.7s30.786,36.949,68.761,36.949S4205.625,857.7,4205.625,857.7Z"
                transform="translate(-4068.101 -821.326)"
              />
              <path
                d="M4149.544,854.669a25.855,25.855,0,1,0-25.856,25.856A25.835,25.835,0,0,0,4149.544,854.669Z"
                transform="translate(-4054.927 -818.008)"
              />
              <path
                d="M4205.625,857.7s-30.786-36.372-68.763-36.372S4068.1,857.7,4068.1,857.7s30.786,36.949,68.761,36.949S4205.625,857.7,4205.625,857.7Z"
                transform="translate(-4068.101 -821.326)"
              />
            </g>
            <line x1="132.105" y2="132.105" transform="translate(3.976 0)" />
            <path
              d="M4149.544,854.669a25.855,25.855,0,1,0-25.856,25.856A25.835,25.835,0,0,0,4149.544,854.669Z"
              transform="translate(-4052.04 -781.526)"
              style={{
                fill: 'var(--color-glass)',
                stroke: 'none',
              }}
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.TakeoutCoffee, () => (
      <svg viewBox="0 0 91.234 135.579">
        <g transform="translate(-172 -5992.836)">
          <g transform="translate(173 5993.835)">
            <path
              d="M7348.6,840.78l-1.2-20.821h75.158l-1.272,20.549"
              transform="translate(-7340.931 -796.528)"
            />
            <path
              d="M7416.955,875.762l-1.842,32.009h-62.66l-1.841-32.009"
              transform="translate(-7339.736 -775.761)"
            />
            <path
              d="M7419.323,890.748h-67.764c-1.778,0-3.258-1.7-3.391-3.9l-2.833-46.591c-.156-2.573,1.484-4.767,3.564-4.767h73.082c2.081,0,3.72,2.194,3.565,4.767l-2.832,46.591C7422.58,889.048,7421.1,890.748,7419.323,890.748Z"
              transform="translate(-7341.702 -790.748)"
            />
            <rect
              width="86.882"
              height="9.703"
              rx="1.854"
              transform="translate(0 13.728)"
            />
            <path
              d="M4.816,0H70.343a4.816,4.816,0,0,1,4.816,4.816v8.912a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.816A4.816,4.816,0,0,1,4.816,0Z"
              transform="translate(6.468 0)"
            />
            <ellipse
              cx="18.265"
              cy="14.664"
              rx="18.265"
              ry="14.664"
              transform="matrix(0.39, -0.921, 0.921, 0.39, 25.18, 83.014)"
            />
            <path
              d="M7382.726,848.555,7376.55,855l3.135,5.92-6.874,6.57"
              transform="translate(-7331.475 -785.886)"
            />
          </g>
          <g
            transform="translate(175.352 5995.404)"
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
          >
            <path
              d="M1.2,20.822h0L0,0H75.159L73.886,20.55Z"
              transform="translate(6.468 23.43)"
            />
            <path
              d="M7416.955,875.762l-1.842,32.009h-62.66l-1.841-32.009"
              transform="translate(-7339.736 -775.761)"
            />
            <rect
              width="86.882"
              height="9.703"
              rx="1.854"
              transform="translate(0 13.728)"
            />
            <path
              d="M4.816,0H70.343a4.816,4.816,0,0,1,4.816,4.816v8.912a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.816A4.816,4.816,0,0,1,4.816,0Z"
              transform="translate(6.468 0)"
            />
            <ellipse
              cx="18.265"
              cy="14.664"
              rx="18.265"
              ry="14.664"
              transform="matrix(0.39, -0.921, 0.921, 0.39, 25.18, 83.014)"
            />
            <path
              d="M7382.726,848.555,7376.55,855l3.135,5.92-6.874,6.57"
              transform="translate(-7331.475 -785.886)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.ClassicPhone, () => (
      <svg viewBox="0 0 135.141 135.14">
        <g transform="translate(-131.854 -6735)">
          <path
            d="M4189.366,1496.3l-25.1-25.1a3.668,3.668,0,0,0-5.187,0l-16.923,16.924c-10.377-.476-23.065-6.6-33.784-17.314s-16.84-23.407-17.314-33.784l16.925-16.923a3.668,3.668,0,0,0,0-5.187l-25.1-25.1a3.665,3.665,0,0,0-5.185,0l-7.744,7.742c-.069.071-.1.16-.165.234-17.156,18.588-6.818,57.646,23.565,88.03s69.443,40.721,88.031,23.566c.074-.062.164-.1.233-.164l7.744-7.744A3.666,3.666,0,0,0,4189.366,1496.3Z"
            transform="translate(-3928.522 5347.256)"
          />
          <path
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
            d="M4189.366,1496.3l-25.1-25.1a3.668,3.668,0,0,0-5.187,0l-16.923,16.924c-10.377-.476-23.065-6.6-33.784-17.314s-16.84-23.407-17.314-33.784l16.925-16.923a3.668,3.668,0,0,0,0-5.187l-25.1-25.1a3.665,3.665,0,0,0-5.185,0l-7.744,7.742c-.069.071-.1.16-.165.234-17.156,18.588-6.818,57.646,23.565,88.03s69.443,40.721,88.031,23.566c.074-.062.164-.1.233-.164l7.744-7.744A3.666,3.666,0,0,0,4189.366,1496.3Z"
            transform="translate(-3924.446 5351.333)"
          />
        </g>
      </svg>
    ))
    .with(FixedColorIcons.CalendarPlaybook, () => (
      <svg viewBox="0 0 136.371 124.413">
        <g transform="translate(-114.665 -1039.81)">
          <g transform="translate(115.664 1040.81)">
            <g transform="translate(0 0)">
              <path
                d="M5928.6,1401.447h21.76v107.431H5823.1V1401.447h21.513"
                transform="translate(-5823.102 -1392.155)"
              />
              <line x2="65.746" transform="translate(30.592 9.293)" />
              <path
                d="M5843.224,1401.447H5823.1v25.018h127.26v-25.018H5928.6"
                transform="translate(-5823.102 -1392.155)"
              />
              <line x1="64.545" transform="translate(30.592 9.293)" />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(34.504 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(58.17 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(81.835 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(105.501 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(10.839 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(34.504 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(58.17 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(81.835 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(105.501 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(10.839 89.143)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(34.504 89.143)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(58.17 89.143)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(81.835 89.143)"
              />
              <rect
                width="8.216"
                height="15.314"
                transform="translate(21.514 0)"
              />
              <rect
                width="8.216"
                height="15.314"
                transform="translate(96.338 0)"
              />
            </g>
            <g
              transform="translate(7.111 5.689)"
              style={{
                fill: 'var(--color-glass)',
                stroke: 'none',
              }}
            >
              <path
                d="M5928.6,1401.447h21.76v107.431H5823.1V1401.447h21.513"
                transform="translate(-5823.102 -1392.155)"
              />
              <line x2="65.746" transform="translate(30.592 9.293)" />
              <path
                d="M5843.224,1401.447H5823.1v25.018h127.26v-25.018H5928.6"
                transform="translate(-5823.102 -1392.155)"
              />
              <line x1="64.545" transform="translate(30.592 9.293)" />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(34.504 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(58.17 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(81.835 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(105.501 45.021)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(10.839 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(34.504 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(58.17 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(81.835 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(105.501 67.082)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(10.839 89.143)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(34.504 89.143)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(58.17 89.143)"
              />
              <rect
                width="13.302"
                height="13.302"
                transform="translate(81.835 89.143)"
              />
              <rect
                width="8.216"
                height="15.314"
                transform="translate(21.514 0)"
              />
              <rect
                width="8.216"
                height="15.314"
                transform="translate(96.338 0)"
              />
            </g>
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.PriceTag, () => (
      <svg viewBox="0 0 131.748 126.776">
        <g transform="translate(-147.005 -4775)">
          <g transform="translate(148 4776)">
            <path
              d="M6766.292,1342.533l-50.716,50.716-68.625-68.626,3.163-46.622,47.554-4.094Z"
              transform="translate(-6637.245 -1273.907)"
            />
            <circle
              cx="4.225"
              cy="4.225"
              r="4.225"
              transform="translate(22.075 13.432)"
            />
            <path
              d="M6664.941,1286.736c-6.594-1.676-23.065,6.23-24.8,22.58-4.624,43.641,68.386,45.68,30.314,84.125"
              transform="translate(-6639.933 -1269.079)"
            />
            <circle
              cx="23.662"
              cy="23.662"
              r="23.662"
              transform="translate(39.299 28.794)"
            />
          </g>
          <g
            transform="translate(159.412 4775.999)"
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
          >
            <path
              d="M68.626,119.343,0,50.717,3.163,4.094,50.716,0l68.626,68.626L68.626,119.343h0Zm-15.37-90.55a23.6,23.6,0,1,0,9.211,1.86A23.5,23.5,0,0,0,53.257,28.794ZM16.6,13.433a4.226,4.226,0,1,0,4.225,4.226A4.23,4.23,0,0,0,16.6,13.433Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.DetailedMonitor, () => (
      <svg viewBox="0 0 132.753 113.767">
        <g transform="translate(-147 -4998)">
          <g transform="translate(148 4999)">
            <g transform="translate(0 0)">
              <rect width="127.34" height="90.122" />
              <line x2="6.829" transform="translate(59.128 5.956)" />
              <g transform="translate(0 81.196)">
                <rect width="127.34" height="8.927" />
              </g>
              <g transform="translate(35.751 103.498)">
                <path
                  d="M47.233,122.284H98.967a4.338,4.338,0,0,1,4.338,4.338v3.931H42.9v-3.931A4.338,4.338,0,0,1,47.233,122.284Z"
                  transform="translate(-42.895 -122.284)"
                />
              </g>
              <g transform="translate(51.197 90.707)">
                <path
                  d="M89.3,120.087H61L65.934,107.3H84.362Z"
                  transform="translate(-60.995 -107.295)"
                />
              </g>
            </g>
            <rect
              width="127.34"
              height="79.882"
              transform="translate(3.413 3.414)"
              style={{
                fill: 'var(--color-glass)',
                stroke: 'none',
              }}
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.RefreshDetailed, () => (
      <svg viewBox="0 0 151.898 168.148">
        <g transform="translate(-112.373 -6898.606)">
          <path
            d="M134.378,60.7,107.3,32.088a6.812,6.812,0,0,0-9.632-.266L69.058,58.9l9.368,9.9L94.58,53.509A40.482,40.482,0,1,1,65.241,15.2l6.552,1.87L75.532,3.964,68.98,2.095a54.114,54.114,0,1,0,39.241,50.791l16.259,17.18Z"
            transform="translate(113.684 7026.938) rotate(-71)"
          />
          <path
            d="M134.378,60.7,107.3,32.088a6.812,6.812,0,0,0-9.632-.266L69.058,58.9l9.368,9.9L94.58,53.509A40.482,40.482,0,1,1,65.241,15.2l6.552,1.87L75.532,3.964,68.98,2.095a54.114,54.114,0,1,0,39.241,50.791l16.259,17.18Z"
            transform="translate(116.911 7030.165) rotate(-71)"
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
          />
        </g>
      </svg>
    ))
    .with(FixedColorIcons.ThumbsDownCuff, () => (
      <svg viewBox="0 0 122.561 134.281">
        <g transform="translate(-127 -6540.996)">
          <g transform="translate(128 6542)">
            <path
              d="M3038.3,1504.773h48.436s6.584-.324,16.406,9.5"
              transform="translate(-3006.238 -1504.772)"
            />
            <path
              d="M3026.224,1516.311"
              transform="translate(-3011.784 -1499.472)"
            />
            <path
              d="M3096.345,1551.22c-14.417,11.711-21.026,35.205-19,50.3,1.343,9.989-3.837,12.735-10.146,12.735-9.363,0-19-12.52-15.112-49.648"
              transform="translate(-3000.308 -1483.436)"
            />
            <path
              d="M3102.9,1505.73h-16.48c-1.83,0-3.313,1.887-3.313,4.215v64.762c0,2.328,1.483,4.213,3.313,4.213h16.48c1.83,0,3.313-1.885,3.313-4.213v-64.762C3106.215,1507.617,3104.732,1505.73,3102.9,1505.73Z"
              transform="translate(-2985.654 -1504.332)"
            />
            <path
              d="M3050.191,1553.087"
              transform="translate(-3000.775 -1482.578)"
            />
            <path
              d="M3034.909,1545.69h-9.245c-5.963,0-9.336,4.832-9.336,10.795h0c0,5.961,3.372,10.795,9.336,10.795h42.449"
              transform="translate(-3016.329 -1485.976)"
            />
            <path
              d="M3052.191,1538.293"
              transform="translate(-2999.856 -1489.374)"
            />
            <path
              d="M3047.373,1530.9h-19.708c-5.963,0-9.335,4.833-9.335,10.795h0c0,5.962,3.372,10.795,9.335,10.795h19.708"
              transform="translate(-3015.41 -1492.772)"
            />
            <path
              d="M3056.649,1523.5"
              transform="translate(-2997.808 -1496.17)"
            />
            <path
              d="M3046.647,1516.1h-14.527c-5.961,0-9.334,4.833-9.334,10.795h0c0,5.961,3.373,10.795,9.334,10.795h4.791"
              transform="translate(-3013.363 -1499.567)"
            />
            <path
              d="M3056.649,1510.9"
              transform="translate(-2997.808 -1501.956)"
            />
            <path
              d="M3060.515,1504.772h-21.354c-4.941,0-10.407,3.171-10.407,8.267,0,4.566,2.547,8.268,7.488,8.268"
              transform="translate(-3010.621 -1504.772)"
            />
          </g>
          <path
            d="M51.739,81.591,50.616,81.3H9.335C3.373,81.3,0,76.471,0,70.509s3.373-10.8,9.335-10.8h2.919c-5.965,0-9.335-4.832-9.335-10.794s3.369-10.794,9.335-10.794h6.505c-5.962,0-9.335-4.835-9.335-10.794S12.8,16.536,18.759,16.536h5.6l.015-.083c-4.125-.572-6.245-4.019-6.245-8.184C18.133,3.171,23.6,0,28.541,0H49.892l0,0h30.6s6.535-.322,16.3,9.386l.041.014v.026L96.9,9.5l-.072-.01-.9,58.327.107-.032-.108.088-.006.421-.934.374C81.3,80.642,75.061,103.359,77.04,118.081c1.344,9.989-3.835,12.736-10.144,12.736C57.569,130.817,47.97,118.388,51.739,81.591Z"
            transform="translate(135.297 6543.459)"
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
          />
        </g>
      </svg>
    ))
    .with(FixedColorIcons.SignedPaper, () => (
      <svg viewBox="0 0 135.839 131.562">
        <g transform="translate(-112.033 -1503.39)">
          <g transform="translate(113.033 1504.39)">
            <g transform="translate(0 0)">
              <g transform="translate(26.393)">
                <path
                  d="M5104.6,2182.174v-44.993h77.809l22.28,22.132v107.43H5104.6v-49.5"
                  transform="translate(-5104.599 -2137.182)"
                />
                <line x2="64.71" transform="translate(19.628 46.441)" />
                <line x2="30.416" transform="translate(19.628 33.795)" />
                <line x2="64.71" transform="translate(19.628 59.907)" />
                <line x2="64.71" transform="translate(19.628 73.037)" />
                <line x2="64.71" transform="translate(19.628 113.334)" />
                <path
                  d="M5180.544,2159.314h-22.132v-22.132Z"
                  transform="translate(-5080.455 -2137.182)"
                />
                <line
                  x2="7.283"
                  y2="7.283"
                  transform="translate(19.628 96.856)"
                />
                <line
                  x1="7.283"
                  y2="7.283"
                  transform="translate(19.628 96.856)"
                />
                <path
                  d="M5127.821,2207.436c3.3-2.539,10.165-3.09,14.477-1.159,1.317.591,2.389,1.365,3.763,1.909a16.816,16.816,0,0,0,11.19-.038c6.648-2.55,8.844,1.437,14.886.917,3.224-.279,6.4-2.026,8.528-.564"
                  transform="translate(-5094.18 -2106.701)"
                />
              </g>
              <g transform="translate(0 6.305)">
                <rect
                  width="11.432"
                  height="50.355"
                  transform="matrix(-0.943, 0.333, -0.333, -0.943, 39.547, 76.441)"
                />
                <path
                  d="M5117.019,2194.3l.207,17.752-10.987-13.945"
                  transform="translate(-5077.471 -2117.86)"
                />
                <rect
                  width="11.432"
                  height="30.713"
                  transform="matrix(-0.943, 0.333, -0.333, -0.943, 22.778, 28.96)"
                />
                <path
                  d="M5091.332,2150.38l-4.952,1.822,10.869,30.774"
                  transform="translate(-5086.38 -2137.565)"
                />
              </g>
            </g>
            <g
              transform="translate(33.75 0)"
              style={{
                fill: 'var(--color-glass)',
                stroke: 'none',
              }}
            >
              <path
                d="M5104.6,2182.174v-44.993h77.809l22.28,22.132v107.43H5104.6v-49.5"
                transform="translate(-5104.599 -2137.182)"
              />
              <line x2="64.71" transform="translate(19.628 46.441)" />
              <line x2="30.416" transform="translate(19.628 33.795)" />
              <line x2="64.71" transform="translate(19.628 59.907)" />
              <line x2="64.71" transform="translate(19.628 73.037)" />
              <line x2="64.71" transform="translate(19.628 113.334)" />
              <path
                d="M5180.544,2159.314h-22.132v-22.132Z"
                transform="translate(-5080.455 -2137.182)"
              />
              <line
                x2="7.283"
                y2="7.283"
                transform="translate(19.628 96.856)"
              />
              <line
                x1="7.283"
                y2="7.283"
                transform="translate(19.628 96.856)"
              />
              <path
                d="M5127.821,2207.436c3.3-2.539,10.165-3.09,14.477-1.159,1.317.591,2.389,1.365,3.763,1.909a16.816,16.816,0,0,0,11.19-.038c6.648-2.55,8.844,1.437,14.886.917,3.224-.279,6.4-2.026,8.528-.564"
                transform="translate(-5094.18 -2106.701)"
              />
            </g>
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.ShoppingCartDetailed, () => (
      <svg viewBox="0 0 139 107.854">
        <g transform="translate(-118 -4585)">
          <g transform="translate(119 4586)">
            <path
              d="M5131.674,480.843h-79.157l-14.028-54.358h102.243Z"
              transform="translate(-5006.009 -409.24)"
            />
            <path
              d="M5048.627,431.868l-4.49-17.245h-27.989"
              transform="translate(-5016.147 -414.623)"
            />
            <line x2="88.453" transform="translate(40.245 44.559)" />
            <line x2="94.268" transform="translate(37.338 30.562)" />
            <line x2="82.638" transform="translate(44.607 59.258)" />
            <line y2="53.843" transform="translate(57.05 17.76)" />
            <line y2="53.843" transform="translate(78.12 17.76)" />
            <line y2="53.843" transform="translate(99.19 17.76)" />
            <line y2="53.843" transform="translate(120.261 17.76)" />
            <path
              d="M5130.089,480.475H5049.5a8.3,8.3,0,0,1-8.3-8.3h0a8.3,8.3,0,0,1,8.3-8.3h80.593"
              transform="translate(-5004.779 -392.272)"
            />
            <circle
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(47.125 88.203)"
            />
            <circle
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(104.23 88.203)"
            />
          </g>
          <g
            transform="translate(154.757 4604.884)"
            style={{ fill: 'var(--color-glass)', stroke: 'none' }}
          >
            <path
              d="M5131.674,480.843h-79.157l-14.028-54.358h102.243Z"
              transform="translate(-5038.488 -426.485)"
            />
            <line x2="88.453" transform="translate(7.767 27.314)" />
            <line x2="94.268" transform="translate(4.859 13.317)" />
            <line x2="82.638" transform="translate(12.128 42.013)" />
            <line y2="53.843" transform="translate(24.099 0.515)" />
            <line y2="53.843" transform="translate(45.297 0.515)" />
            <line y2="53.843" transform="translate(66.495 0.515)" />
            <line y2="53.843" transform="translate(87.782 0.515)" />
            <circle
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(14.155 70.959)"
            />
            <circle
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(71.751 70.959)"
            />
          </g>
        </g>
      </svg>
    ))
    .with(FixedColorIcons.CheckmarkGreenCircle, () => (
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Green check">
          <circle
            id="Ellipse 329"
            cx="5"
            cy="6"
            r="5"
            fill="#E7F7E8"
            stroke="none"
          />
          <path
            id="Vector 7"
            d="M1.875 5.82143L5 8.5L10 1"
            stroke="#11B21B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ))
    .with(FixedColorIcons.ExclamationYellow, () => (
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          stroke="none"
          cx="9"
          cy="11"
          r="9"
          fill="#FFCB1B"
          fill-opacity="0.3"
        />
        <path
          d="M10.0425 0.904499V8.6265C10.0425 9.0315 10.0335 9.4275 10.0155 9.8145C9.9975 10.2015 9.975 10.593 9.948 10.989C9.921 11.376 9.885 11.7765 9.84 12.1905C9.804 12.5955 9.759 13.032 9.705 13.5H8.085C8.031 13.032 7.9815 12.5955 7.9365 12.1905C7.9005 11.7765 7.869 11.376 7.842 10.989C7.815 10.593 7.7925 10.2015 7.7745 9.8145C7.7565 9.4275 7.7475 9.0315 7.7475 8.6265V0.904499H10.0425ZM7.167 18.765C7.167 18.531 7.2075 18.3105 7.2885 18.1035C7.3785 17.8965 7.5 17.7165 7.653 17.5635C7.806 17.4105 7.9815 17.289 8.1795 17.199C8.3865 17.109 8.6115 17.064 8.8545 17.064C9.0885 17.064 9.3045 17.109 9.5025 17.199C9.7095 17.289 9.8895 17.4105 10.0425 17.5635C10.1955 17.7165 10.317 17.8965 10.407 18.1035C10.497 18.3105 10.542 18.531 10.542 18.765C10.542 19.008 10.497 19.233 10.407 19.44C10.317 19.638 10.1955 19.8135 10.0425 19.9665C9.8895 20.1195 9.7095 20.2365 9.5025 20.3175C9.3045 20.4075 9.0885 20.4525 8.8545 20.4525C8.6115 20.4525 8.3865 20.4075 8.1795 20.3175C7.9815 20.2365 7.806 20.1195 7.653 19.9665C7.5 19.8135 7.3785 19.638 7.2885 19.44C7.2075 19.233 7.167 19.008 7.167 18.765Z"
          fill="#F09025"
          stroke="none"
        />
      </svg>
    ))
    .with(FixedColorIcons.RedXCircle, () => (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle stroke="none" cx="5" cy="5" r="5" fill="#FBECED" />
        <line
          x1="8.49414"
          y1="1.54011"
          x2="1.54092"
          y2="8.49333"
          stroke="#DB3C48"
          strokeLinecap="round"
        />
        <line
          x1="8.37688"
          y1="8.49414"
          x2="1.42366"
          y2="1.54092"
          stroke="#DB3C48"
          strokeLinecap="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.InfoBlue, () => (
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          stroke="none"
          cx="9"
          cy="10.5"
          r="9"
          fill="#A2E2FC"
          fillOpacity="0.5"
        />
        <path
          stroke="none"
          d="M9.6915 6.0745V19.75H7.2885V6.0745H9.6915ZM10.2045 1.7815C10.2045 2.0155 10.155 2.236 10.056 2.443C9.966 2.641 9.84 2.821 9.678 2.983C9.525 3.136 9.3405 3.2575 9.1245 3.3475C8.9175 3.4375 8.697 3.4825 8.463 3.4825C8.229 3.4825 8.0085 3.4375 7.8015 3.3475C7.6035 3.2575 7.428 3.136 7.275 2.983C7.122 2.821 7.0005 2.641 6.9105 2.443C6.8205 2.236 6.7755 2.0155 6.7755 1.7815C6.7755 1.5475 6.8205 1.327 6.9105 1.12C7.0005 0.903999 7.122 0.719499 7.275 0.566498C7.428 0.404499 7.6035 0.278499 7.8015 0.188498C8.0085 0.0984985 8.229 0.0534985 8.463 0.0534985C8.697 0.0534985 8.9175 0.0984985 9.1245 0.188498C9.3405 0.278499 9.525 0.404499 9.678 0.566498C9.84 0.719499 9.966 0.903999 10.056 1.12C10.155 1.327 10.2045 1.5475 10.2045 1.7815Z"
          fill="#2CA7E4"
        />
      </svg>
    ))
    .with(FixedColorIcons.CheckmarkInBox, () => (
      <svg
        width="56"
        height="41"
        viewBox="0 0 56 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect stroke="none" width="56" height="41" rx="8" fill="#80CAEF" />
        <path
          d="M33.583 19.5837C33.9997 20.5837 34.2497 21.7503 34.1663 22.8337C34.1663 27.7503 30.1663 31.7503 25.2497 31.7503C20.333 31.7503 16.333 27.7503 16.333 22.8337C16.333 17.917 20.333 13.917 25.2497 13.917C26.9997 13.917 28.7497 14.417 30.2497 15.417"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.1667 12.833L25.8333 27.1663L19.25 22.833L22.5833 19.4997L25.9167 22.9164L35.6667 8.83301L40.1667 12.833Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.Browser, () => (
      <svg
        width="56"
        height="42"
        viewBox="0 0 56 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.5"
          width="56"
          height="41"
          rx="8"
          stroke="none"
          fill="#2CA7E4"
          fillOpacity="0.2"
        />
        <path
          d="M43.82 8.5H13V33.8354H43.82V8.5Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2178 14.6699H43.4768"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.2071 12.9874C17.9644 12.9874 18.5783 12.3736 18.5783 11.6163C18.5783 10.859 17.9644 10.2451 17.2071 10.2451C16.4498 10.2451 15.8359 10.859 15.8359 11.6163C15.8359 12.3736 16.4498 12.9874 17.2071 12.9874Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.8507 12.9874C22.6079 12.9874 23.2218 12.3736 23.2218 11.6163C23.2218 10.859 22.6079 10.2451 21.8507 10.2451C21.0934 10.2451 20.4795 10.859 20.4795 11.6163C20.4795 12.3736 21.0934 12.9874 21.8507 12.9874Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5245 12.9874C27.2818 12.9874 27.8956 12.3736 27.8956 11.6163C27.8956 10.859 27.2818 10.2451 26.5245 10.2451C25.7672 10.2451 25.1533 10.859 25.1533 11.6163C25.1533 12.3736 25.7672 12.9874 26.5245 12.9874Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.5191 31.8097C32.6497 31.8097 35.9982 28.4612 35.9982 24.3306C35.9982 20.2001 32.6497 16.8516 28.5191 16.8516C24.3885 16.8516 21.04 20.2001 21.04 24.3306C21.04 28.4612 24.3885 31.8097 28.5191 31.8097Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.8789 29.2237C23.0036 27.3539 23.284 27.1981 23.4398 27.0735C24.0943 26.606 24.8422 26.2632 25.6524 26.1074C27.1482 27.6656 29.6412 27.6967 31.1994 26.2009L31.2929 26.1074C32.1031 26.2632 32.851 26.606 33.5054 27.0735C33.6924 27.1981 34.004 27.3228 34.1287 29.286"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.3549 21.6195C31.3549 23.1777 30.3577 25.4837 28.4567 25.4837C26.5558 25.4837 25.5586 23.1777 25.5586 21.6195C25.6521 20.0302 27.0233 18.8149 28.6126 18.9084C30.1084 18.9707 31.2614 20.1549 31.3549 21.6195Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.XToggle, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" fill="none">
        <circle stroke="none" cx="14.5" cy="14.5" r="14.5" fill="#DB3C48" />
        <path
          d="M22.39 6.45044L7.00002 21.8377"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M22.2855 21.7333L7.00396 6.45439"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.CheckmarkToggle, () => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" fill="none">
        <circle stroke="none" cx="14.5" cy="14.5" r="14.5" fill="#11B21B" />
        <path
          d="M5 17.4202L11.7538 23.2092L22.56 7"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.MagnifyingGlassColor, () => (
      <svg
        width="100"
        height="103"
        viewBox="0 0 100 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="none"
      >
        <path
          d="M68 61L95.6865 88.6004C98.7864 91.8004 98.7864 96.8004 95.6865 100C92.4865 103.1 87.4865 103.1 84.2865 100L54 71"
          fill="#F09025"
          fillOpacity="0.5"
          stroke="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.823 54.4479L11.8879 54.3996C11.8667 54.4155 11.845 54.4316 11.823 54.4479Z"
          fill="#FFCB1B"
          fillOpacity="0.5"
          stroke="none"
        />
        <path
          d="M11.8879 54.3996C15.0879 51.9996 18.8879 50.3996 22.7879 49.5996C30.1879 57.2996 42.3879 57.4996 50.0879 50.0996L50.5879 49.5996C54.4879 50.3996 58.2879 51.9996 61.4879 54.3996C62.3879 54.9996 63.8879 55.6996 64.5879 65.2996C64.5879 65.2996 54.5 77.5 36.5 77.5C18.5 77.5 9.08789 64.9996 9.08789 64.9996C9.67197 56.0437 11.0142 55.048 11.823 54.4479L11.8879 54.3996Z"
          fill="#FFCB1B"
          fillOpacity="0.5"
          stroke="none"
        />
        <path
          d="M50.9877 27.6C50.9877 35.3 46.0877 46.6 36.6877 46.6C27.2877 46.6 22.3877 35.3 22.3877 27.6C22.8877 19.7 29.5877 13.7 37.4877 14.2C44.6877 14.6 50.4877 20.4 50.9877 27.6Z"
          fill="#FFCB1B"
          fillOpacity="0.5"
          stroke="none"
        />
        <path
          d="M69.7868 58.2002L95.6868 83.6002C98.7868 86.8002 98.7868 91.8002 95.6868 95.0002C92.4868 98.1002 87.4868 98.1002 84.2868 95.0002L58.2539 69.5411"
          stroke="#F09025"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.2871 70.2002L87.2871 87.1002"
          stroke="#F09025"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0879 61.9996C11.6879 52.7996 13.0879 51.9996 13.8879 51.3996C17.0879 48.9996 20.8879 47.3996 24.7879 46.5996C32.1879 54.2996 44.3879 54.4996 52.0879 47.0996L52.5879 46.5996C56.4879 47.3996 60.2879 48.9996 63.4879 51.3996C64.3879 51.9996 65.8879 52.6996 66.5879 62.2996"
          stroke="#FFCB1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.9877 24.6C52.9877 32.3 48.0877 43.6 38.6877 43.6C29.2877 43.6 24.3877 32.3 24.3877 24.6C24.8877 16.7 31.5877 10.7 39.4877 11.2C46.6877 11.6 52.4877 17.4 52.9877 24.6Z"
          stroke="#FFCB1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.8873 74.8C59.2666 74.8 75.7873 58.2793 75.7873 37.9C75.7873 17.5207 59.2666 1 38.8873 1C18.508 1 1.9873 17.5207 1.9873 37.9C1.9873 58.2793 18.508 74.8 38.8873 74.8Z"
          stroke="#01293D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.BrowserGray, () => (
      <svg
        width="40"
        height="33"
        viewBox="0 0 40 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_386_10773)">
          <path
            d="M37.4945 1H1V31H37.4945V1Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.2583 8.30615H37.0885"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.98152 6.31364C6.87822 6.31364 7.60514 5.58672 7.60514 4.69002C7.60514 3.79332 6.87822 3.06641 5.98152 3.06641C5.08483 3.06641 4.35791 3.79332 4.35791 4.69002C4.35791 5.58672 5.08483 6.31364 5.98152 6.31364Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.4796 6.31364C12.3763 6.31364 13.1032 5.58672 13.1032 4.69002C13.1032 3.79332 12.3763 3.06641 11.4796 3.06641C10.5829 3.06641 9.85596 3.79332 9.85596 4.69002C9.85596 5.58672 10.5829 6.31364 11.4796 6.31364Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.0147 6.31364C17.9114 6.31364 18.6383 5.58672 18.6383 4.69002C18.6383 3.79332 17.9114 3.06641 17.0147 3.06641C16.118 3.06641 15.3911 3.79332 15.3911 4.69002C15.3911 5.58672 16.118 6.31364 17.0147 6.31364Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.3766 28.6013C24.2677 28.6013 28.2327 24.6363 28.2327 19.7452C28.2327 14.8542 24.2677 10.8892 19.3766 10.8892C14.4855 10.8892 10.5205 14.8542 10.5205 19.7452C10.5205 24.6363 14.4855 28.6013 19.3766 28.6013Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.6973 25.5389C12.8449 23.3249 13.177 23.1404 13.3615 22.9928C14.1364 22.4393 15.022 22.0334 15.9814 21.8489C17.7526 23.6939 20.7046 23.7308 22.5497 21.9596L22.6604 21.8489C23.6198 22.0334 24.5054 22.4393 25.2803 22.9928C25.5017 23.1404 25.8707 23.288 26.0183 25.6127"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.7341 16.5352C22.7341 18.3802 21.5533 21.1108 19.3023 21.1108C17.0514 21.1108 15.8706 18.3802 15.8706 16.5352C15.9813 14.6532 17.6049 13.2141 19.4868 13.3248C21.2581 13.3986 22.6234 14.8008 22.7341 16.5352Z"
            stroke="#80949E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_386_10773"
            x="0"
            y="0"
            width="39.4946"
            height="33"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.00392157 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_386_10773"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_386_10773"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    ))
    .with(FixedColorIcons.CalendarPlus, () => (
      <svg
        width="109"
        height="97"
        viewBox="0 0 109 97"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.8999 7.5H90.1001V82.8H1V7.5H16"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.3999 7.5H68.5"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1001 7.5H1V25.1H90.1001V7.5H74.8999"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.6001 7.5H22.3999"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.3999 32.6001H25.1001V41.9001H34.3999V32.6001Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.0002 32.6001H41.7002V41.9001H51.0002V32.6001Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.5999 32.6001H58.2998V41.9001H67.5999V32.6001Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.2 32.6001H74.8999V41.9001H84.2V32.6001Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8 48H8.5V57.3H17.8V48Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.3999 48H25.1001V57.3H34.3999V48Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.0002 48H41.7002V57.3H51.0002V48Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.5999 48H58.2998V57.3H67.5999V48Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.2 48H74.8999V57.3H84.2V48Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8 63.5H8.5V72.8H17.8V63.5Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.3999 63.5H25.1001V72.8H34.3999V63.5Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.0002 63.5H41.7002V72.8H51.0002V63.5Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.5999 63.5H58.2998V72.8H67.5999V63.5Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.8 1H16V11.7H21.8V1Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.3 1H68.5V11.7H74.3V1Z"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.0498 95.3049C96.7524 95.3049 107.05 85.0075 107.05 72.3049C107.05 59.6024 96.7524 49.3049 84.0498 49.3049C71.3473 49.3049 61.0498 59.6024 61.0498 72.3049C61.0498 85.0075 71.3473 95.3049 84.0498 95.3049Z"
          fill="white"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.0498 56.5334V86.1554"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M98.8608 71.3445H69.2388"
          stroke="#E3E3E3"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.CalendarDetailed, () => (
      <svg
        width="37"
        height="32"
        viewBox="0 0 37 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36 31H0.999999L1 9H36V31Z"
          fill="#D9DFE2"
          stroke="#D9DFE2"
          strokeWidth="2"
        />
        <path
          d="M13.8703 12.5894H11.426C11.1498 12.5894 10.926 12.8132 10.926 13.0894V15.5001C10.926 15.7763 11.1498 16.0001 11.426 16.0001H13.8703C14.1465 16.0001 14.3703 15.7763 14.3703 15.5001V13.0894C14.3703 12.8132 14.1465 12.5894 13.8703 12.5894Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M20.0186 12.5894H17.5741C17.298 12.5894 17.0741 12.8132 17.0741 13.0894V15.5001C17.0741 15.7763 17.298 16.0001 17.5741 16.0001H20.0186C20.2947 16.0001 20.5186 15.7763 20.5186 15.5001V13.0894C20.5186 12.8132 20.2947 12.5894 20.0186 12.5894Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M26.1666 12.5894H23.7221C23.446 12.5894 23.2221 12.8132 23.2221 13.0894V15.5001C23.2221 15.7763 23.446 16.0001 23.7221 16.0001H26.1666C26.4427 16.0001 26.6666 15.7763 26.6666 15.5001V13.0894C26.6666 12.8132 26.4427 12.5894 26.1666 12.5894Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M32.3148 12.5894H29.8703C29.5942 12.5894 29.3703 12.8132 29.3703 13.0894V15.5001C29.3703 15.7763 29.5942 16.0001 29.8703 16.0001H32.3148C32.5909 16.0001 32.8148 15.7763 32.8148 15.5001V13.0894C32.8148 12.8132 32.5909 12.5894 32.3148 12.5894Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M7.72223 18.2373H5.27777C5.00163 18.2373 4.77777 18.4612 4.77777 18.7373V21.1481C4.77777 21.4242 5.00163 21.6481 5.27777 21.6481H7.72223C7.99837 21.6481 8.22223 21.4242 8.22223 21.1481V18.7373C8.22223 18.4612 7.99837 18.2373 7.72223 18.2373Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M13.8703 18.2373H11.426C11.1498 18.2373 10.926 18.4612 10.926 18.7373V21.1481C10.926 21.4242 11.1498 21.6481 11.426 21.6481H13.8703C14.1465 21.6481 14.3703 21.4242 14.3703 21.1481V18.7373C14.3703 18.4612 14.1465 18.2373 13.8703 18.2373Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M20.0186 18.2373H17.5741C17.298 18.2373 17.0741 18.4612 17.0741 18.7373V21.1481C17.0741 21.4242 17.298 21.6481 17.5741 21.6481H20.0186C20.2947 21.6481 20.5186 21.4242 20.5186 21.1481V18.7373C20.5186 18.4612 20.2947 18.2373 20.0186 18.2373Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M26.1666 18.2373H23.7221C23.446 18.2373 23.2221 18.4612 23.2221 18.7373V21.1481C23.2221 21.4242 23.446 21.6481 23.7221 21.6481H26.1666C26.4427 21.6481 26.6666 21.4242 26.6666 21.1481V18.7373C26.6666 18.4612 26.4427 18.2373 26.1666 18.2373Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M32.3148 18.2373H29.8703C29.5942 18.2373 29.3703 18.4612 29.3703 18.7373V21.1481C29.3703 21.4242 29.5942 21.6481 29.8703 21.6481H32.3148C32.5909 21.6481 32.8148 21.4242 32.8148 21.1481V18.7373C32.8148 18.4612 32.5909 18.2373 32.3148 18.2373Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M7.72223 23.9219H5.27777C5.00163 23.9219 4.77777 24.1457 4.77777 24.4219V26.8326C4.77777 27.1088 5.00163 27.3326 5.27777 27.3326H7.72223C7.99837 27.3326 8.22223 27.1088 8.22223 26.8326V24.4219C8.22223 24.1457 7.99837 23.9219 7.72223 23.9219Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M13.8703 23.9219H11.426C11.1498 23.9219 10.926 24.1457 10.926 24.4219V26.8326C10.926 27.1088 11.1498 27.3326 11.426 27.3326H13.8703C14.1465 27.3326 14.3703 27.1088 14.3703 26.8326V24.4219C14.3703 24.1457 14.1465 23.9219 13.8703 23.9219Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M20.0186 23.9219H17.5741C17.298 23.9219 17.0741 24.1457 17.0741 24.4219V26.8326C17.0741 27.1088 17.298 27.3326 17.5741 27.3326H20.0186C20.2947 27.3326 20.5186 27.1088 20.5186 26.8326V24.4219C20.5186 24.1457 20.2947 23.9219 20.0186 23.9219Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M26.1666 23.9219H23.7221C23.446 23.9219 23.2221 24.1457 23.2221 24.4219V26.8326C23.2221 27.1088 23.446 27.3326 23.7221 27.3326H26.1666C26.4427 27.3326 26.6666 27.1088 26.6666 26.8326V24.4219C26.6666 24.1457 26.4427 23.9219 26.1666 23.9219Z"
          fill="white"
          stroke="none"
        />
        <path
          d="M1 3H36V8H1V3Z"
          fill="#607986"
          stroke="#607986"
          strokeWidth="2"
        />
        <path
          d="M9.20371 0H8.05554C7.7794 0 7.55554 0.223858 7.55554 0.500001V3.42421C7.55554 3.70035 7.7794 3.92421 8.05554 3.92421H9.20371C9.47985 3.92421 9.70371 3.70035 9.70371 3.42421V0.5C9.70371 0.223858 9.47985 0 9.20371 0Z"
          fill="#D9DFE2"
          stroke="none"
        />
        <path
          d="M28.6481 0H27.5C27.2238 0 27 0.223858 27 0.500001V3.42421C27 3.70035 27.2238 3.92421 27.5 3.92421H28.6481C28.9243 3.92421 29.1481 3.70035 29.1481 3.42421V0.5C29.1481 0.223858 28.9243 0 28.6481 0Z"
          fill="#D9DFE2"
          stroke="none"
        />
      </svg>
    ))
    .with(FixedColorIcons.RedX, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 6.2002L6 18.2002"
          stroke="#FF7C60"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 6.2002L18 18.2002"
          stroke="#FF7C60"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ))
    .with(FixedColorIcons.LinkedInGray, () => (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_998_2643)">
          <path
            stroke="none"
            d="M21.5812 22.0649H17.8298V16.1833C17.8298 14.78 17.8007 12.9777 15.8756 12.9777C13.9201 12.9777 13.6212 14.4975 13.6212 16.0807V22.0624H9.86983V9.97741H13.473V11.6239H13.5212C13.8825 11.0075 14.4041 10.5005 15.0305 10.1569C15.6569 9.81321 16.3648 9.64571 17.0788 9.67218C20.8783 9.67218 21.5824 12.1735 21.5824 15.4297V22.0598L21.5812 22.0649ZM5.63339 8.32336C5.2026 8.32336 4.78149 8.19563 4.42328 7.95632C4.06508 7.71702 3.78587 7.37687 3.62095 6.9789C3.45604 6.58093 3.41283 6.14299 3.49678 5.72046C3.58073 5.29793 3.78807 4.90978 4.0926 4.60508C4.39712 4.30038 4.78515 4.09281 5.20764 4.00861C5.63012 3.92442 6.06808 3.96737 6.46615 4.13206C6.86421 4.29674 7.20452 4.57575 7.44403 4.93382C7.68355 5.29188 7.81152 5.71292 7.81177 6.14371C7.81211 6.42994 7.756 6.71341 7.64666 6.97793C7.53732 7.24244 7.37689 7.4828 7.17456 7.68525C6.97223 7.8877 6.73196 8.04826 6.46751 8.15776C6.20306 8.26725 5.91961 8.32353 5.63339 8.32336ZM7.51414 22.0649H3.75264V9.97741H7.51414V22.0649ZM23.4556 0.478659H1.86809C1.62559 0.475815 1.38491 0.520766 1.15978 0.610946C0.934659 0.701126 0.729503 0.834768 0.556032 1.00424C0.382561 1.17371 0.244171 1.3757 0.148767 1.59866C0.0533622 1.82162 0.002811 2.06119 0 2.30369L0 23.9836C0.002811 24.2261 0.0533622 24.4657 0.148767 24.6887C0.244171 24.9116 0.382561 25.1136 0.556032 25.2831C0.729503 25.4525 0.934659 25.5862 1.15978 25.6764C1.38491 25.7666 1.62559 25.8115 1.86809 25.8087H23.4543C23.9449 25.8147 24.4178 25.626 24.7695 25.2839C25.1211 24.9418 25.3227 24.4742 25.33 23.9836V2.30369C25.3227 1.81314 25.1211 1.34553 24.7695 1.0034C24.4178 0.661278 23.9449 0.472568 23.4543 0.478659H23.4556Z"
            fill="#B3BFC5"
          />
        </g>
        <defs>
          <clipPath id="clip0_998_2643">
            <rect
              width="25.33"
              height="25.33"
              fill="white"
              transform="translate(0 0.478516)"
            />
          </clipPath>
        </defs>
      </svg>
    ))

    .with(FixedColorIcons.MagnifyingGlass, () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
      >
        <path
          d="M4.54593 8.61483C6.46052 8.61483 8.01261 7.0627 8.01261 5.14811C8.01261 3.23353 6.46052 1.68148 4.54593 1.68148C2.63135 1.68148 1.07927 3.23353 1.07927 5.14811C1.07927 7.0627 2.63135 8.61483 4.54593 8.61483Z"
          stroke="#72F5C8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.846 10.4146L7.146 7.64795"
          stroke="#72F5C8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ))
    .with(null, () => null)
    .with(undefined, () => null)
    .exhaustive()

  return FixedColorIconComponent
    ? React.cloneElement(FixedColorIconComponent, {
        xlmns: 'http://www.w3.org/2000/svg',
        className: cn(styles.svg, className, 'icon', `icon-${icon}`),
        style,
      })
    : null
}
